import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';

const NotFound = () => {
  const location = useLocation();

  return (
    <Layout className="page-content">
      <Content className="content-padding">
        <div>
          <h2>404 - Page not Found</h2>
          <p>We are sorry but the page you are looking for does not exist.</p>
        </div>
      </Content>
    </Layout>
  );
};

export default NotFound;
