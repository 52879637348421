
// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import IndexVM from '../models/IndexVM';
import AlertVM from '../models/AlertVM';
import SupplyChainFileTypeVM from '../models/SupplyChainFileTypeVM';
import AlertCountVM from '../models/AlertCountVM';

export class SystemAlertsApiService extends BaseApi {

    // get: SystemAlertsApiService.GetIndexVM
    public getIndexVM_Url = (): string => {
        let url = `api/systemAlert/getIndexVM`;

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public getIndexVM = (): Promise<IndexVM> => {
        return this.SendRequest<any, IndexVM>(null, 'get', this.getIndexVM_Url(), true, false);
    }

    // get: SystemAlertsApiService.MarkAsViewed
    public markAsViewed_Url = (alertTypeCode: string): string => {
        let url = `api/systemAlert/markAsViewed`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (alertTypeCode != null) {
            url += `${prefix}alertTypeCode=${encodeURIComponent(alertTypeCode)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public markAsViewed = (alertTypeCode: string): Promise<boolean> => {
        return this.SendRequest<any, boolean>(null, 'get', this.markAsViewed_Url(alertTypeCode), true, false);
    }

    // get: SystemAlertsApiService.GetAlertListByUser
    public getAlertListByUser_Url = (alertTypeCode: string): string => {
        let url = `api/systemAlert/getAlertListByUser`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (alertTypeCode != null) {
            url += `${prefix}alertTypeCode=${encodeURIComponent(alertTypeCode)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public getAlertListByUser = (alertTypeCode: string): Promise<AlertVM[]> => {
        return this.SendRequest<any, AlertVM[]>(null, 'get', this.getAlertListByUser_Url(alertTypeCode), true, false);
    }

    // post: SystemAlertsApiService.ExportTable
    public exportTable_Url = (alertCode: string, tableRequest: TableRequestDTO): string => {
        let url = `api/systemAlert/exportTable`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (alertCode != null) {
            url += `${prefix}alertCode=${encodeURIComponent(alertCode)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public exportTable = (alertCode: string, tableRequest: TableRequestDTO): Promise<void> => {
        return this.SendRequest<any, void>(tableRequest, 'post', this.exportTable_Url(alertCode, tableRequest), true, false);
    }

    // get: SystemAlertsApiService.GetItemFormulaErrorsList
    public getItemFormulaErrorsList_Url = (number: string): string => {
        let url = `api/systemAlert/getItemFormulaErrorsList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (number != null) {
            url += `${prefix}number=${encodeURIComponent(number)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public getItemFormulaErrorsList = (number: string): Promise<string[]> => {
        return this.SendRequest<any, string[]>(null, 'get', this.getItemFormulaErrorsList_Url(number), true, false);
    }

    // get: SystemAlertsApiService.GetSpMissingAccountsList
    public getSpMissingAccountsList_Url = (salesPersonId: string): string => {
        let url = `api/systemAlert/getSpMissingAccountsList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (salesPersonId != null) {
            url += `${prefix}salesPersonId=${encodeURIComponent(salesPersonId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public getSpMissingAccountsList = (salesPersonId: string): Promise<string[]> => {
        return this.SendRequest<any, string[]>(null, 'get', this.getSpMissingAccountsList_Url(salesPersonId), true, false);
    }

    // get: SystemAlertsApiService.GetSopHoldDataList
    public getSopHoldDataList_Url = (sopNumber: string): string => {
        let url = `api/systemAlert/getSopHoldDataList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (sopNumber != null) {
            url += `${prefix}sopNumber=${encodeURIComponent(sopNumber)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public getSopHoldDataList = (sopNumber: string): Promise<string[]> => {
        return this.SendRequest<any, string[]>(null, 'get', this.getSopHoldDataList_Url(sopNumber), true, false);
    }

    // get: SystemAlertsApiService.GetBOMItemMissingPackagingDataList
    public getBOMItemMissingPackagingDataList_Url = (number: string): string => {
        let url = `api/systemAlert/getBOMItemMissingPackagingDataList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (number != null) {
            url += `${prefix}number=${encodeURIComponent(number)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public getBOMItemMissingPackagingDataList = (number: string): Promise<string[]> => {
        return this.SendRequest<any, string[]>(null, 'get', this.getBOMItemMissingPackagingDataList_Url(number), true, false);
    }

    // get: SystemAlertsApiService.GetItemPackagingMissingBOMDataList
    public getItemPackagingMissingBOMDataList_Url = (number: string): string => {
        let url = `api/systemAlert/getItemPackagingMissingBOMDataList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (number != null) {
            url += `${prefix}number=${encodeURIComponent(number)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public getItemPackagingMissingBOMDataList = (number: string): Promise<string[]> => {
        return this.SendRequest<any, string[]>(null, 'get', this.getItemPackagingMissingBOMDataList_Url(number), true, false);
    }

    // get: SystemAlertsApiService.GetInventoryNoCertFileTypesList
    public getInventoryNoCertFileTypesList_Url = (): string => {
        let url = `api/systemAlert/getInventoryNoCertFileTypesList`;

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public getInventoryNoCertFileTypesList = (): Promise<SupplyChainFileTypeVM[]> => {
        return this.SendRequest<any, SupplyChainFileTypeVM[]>(null, 'get', this.getInventoryNoCertFileTypesList_Url(), true, false);
    }

    // get: SystemAlertsApiService.ToggleIsIgnored
    public toggleIsIgnored_Url = (id: number): string => {
        let url = `api/systemAlert/toggleIsIgnored`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public toggleIsIgnored = (id: number): Promise<boolean> => {
        return this.SendRequest<any, boolean>(null, 'get', this.toggleIsIgnored_Url(id), true, false);
    }

    // get: SystemAlertsApiService.GetAlertCount
    public getAlertCount_Url = (): string => {
        let url = `api/systemAlert/getAlertCount`;

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public getAlertCount = (): Promise<AlertCountVM> => {
        return this.SendRequest<any, AlertCountVM>(null, 'get', this.getAlertCount_Url(), true, false);
    }
}
var service = new SystemAlertsApiService();
export default service;
