import { message } from 'antd';
import { ArgsProps } from 'antd/lib/message';
import React from 'react';

export default class Message {
  public static readonly UNEXPECTED_ERROR: string =
    'An unexpected error has occurred. Please contact an administrator.';

  public static UnexpectedError(error?: string) {
    if (error) {
      console.log(error);
    }
    message.error(this.UNEXPECTED_ERROR, this.duration(this.UNEXPECTED_ERROR));
  }

  public static open(args: ArgsProps) {
    if (args.duration == undefined) {
      args.duration = this.duration(args.content);
    }
    message.open(args);
  }

  public static error(content: string) {
    message.error(content, this.duration(content));
  }

  public static success(content: string) {
    message.success(content, this.duration(content));
  }

  public static info(content: string) {
    message.info(content, this.duration(content));
  }

  public static loading(content: string) {
    message.loading(content, this.duration(content));
  }

  private static duration(content: any): number | undefined {
    if (typeof content === 'string' || content instanceof String) {
      //1 minute per 1000 characters, max 20 seconds, min 3 seconds
      return Math.max(Math.min((content.length / 1000) * 60, 20), 3);
    }
  }
}
