import { Spin } from 'antd';
import React from 'react';

interface SpinCenteredProps {
  text: string | null;
}

export const SpinCentered = (props: SpinCenteredProps) => {
  return (
    <div className="spin-container">
      <Spin tip={props.text}></Spin>
    </div>
  );
};
