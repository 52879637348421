import './App.less';
import './layout/BioZymeLayout.less';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import Routes, { PageInfo } from './consts/Routes';
import { AuthenticationContext } from './auth/AuthenticationContext';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

const BrandsList = React.lazy(() => import('./pages/Brands/List'));
const BrandsDetail = React.lazy(() => import('./pages/Brands/Detail'));
const CustomPricing = React.lazy(() => import('./pages/CustomPricing/List'));
const DistributionsList = React.lazy(() => import('./pages/Distributions/List'));
const FreightPage = React.lazy(() => import('./pages/Freight/Freight'));
const PromotionsList = React.lazy(() => import('./pages/Promotions/List'));
const CheckPricing = React.lazy(() => import('./pages/CheckPricing/List'));
const TimesPage = React.lazy(() => import('./pages/Times/Times'));
const SystemAlerts = React.lazy(() => import('./pages/SystemAlerts/List'));
const ReportsList = React.lazy(() => import('./pages/Reports/List'));
const Reworks = React.lazy(() => import('./pages/Reworks/List'));
const Purchasing = React.lazy(() => import('./pages/Purchasing/List'));
const ProductType = React.lazy(() => import('./pages/ProductTypes/List'));
const Tracking = React.lazy(() => import('./pages/TrackingSetup/List'));
const TrackingPage = React.lazy(() => import('./pages/Tracking/TrackingPage'));
const MixingScheduler = React.lazy(() => import('./pages/MixingScheduler/List'));
const SupplyChain = React.lazy(() => import('./pages/SupplyChain/List'));
const SupplyChainDetail = React.lazy(() => import('./pages/SupplyChain/Details'));
const SupplyChainReqDetail = React.lazy(() => import('./pages/SupplyChain/RequestDetail'));
const ForecastingList = React.lazy(() => import('./pages/Forecasting/List'));
const Vendors = React.lazy(() => import('./pages/Vendors/List'));
const VendorDetails = React.lazy(() => import('./pages/Vendors/VendorDetails'));
const RequestedTable = React.lazy(() => import('./pages/Vendors/RequestedTable'));
const SalesOrder = React.lazy(() => import('./pages/SalesOrders/List'));
const SalesOrderEdit_Standalone = React.lazy(() => import('./pages/SalesOrders/Edit/Standalone'));
const SalesOrderEdit = React.lazy(() => import('./pages/SalesOrders/Edit/Edit'));
const Create_Standalone = React.lazy(() => import('./pages/SalesOrders/Create/Standalone'));
const Create = React.lazy(() => import('./pages/SalesOrders/Create/Create'));
const Unauthorized = React.lazy(() => import('./auth/Unauthorized'));
const ProductionSetupPage = React.lazy(() => import('./pages/Production/Setup/SetupPage'));
const PlanningPage = React.lazy(() => import('./pages/Planning/PlanningPage'));
const AoRequest = React.lazy(() => import('./pages/AoRequest/List'));
const AoRequestDetail = React.lazy(() => import('./pages/AoRequest/Detail'));
const CreditCardRetry = React.lazy(() => import('./pages/SalesOrders/CreditCardRetry'));
const CustomerDesync = React.lazy(() => import('./pages/SalesOrders/CustomerDesync'));
const WikiList = React.lazy(() => import('./pages/Wiki/List'));
const WikiDetail = React.lazy(() => import('./pages/Wiki/Detail'));
const DealerRetreatDiscountsList = React.lazy(() => import('./pages/DealerRetreatDiscounts/List'));

import {
  BellFilled,
  BookFilled,
  ExclamationCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Alert, Badge, Button, List, Menu, Modal, Popover, Space } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import Logo from './content/logo.png';
import { Link } from 'react-router-dom';
import { LoadComponent } from './shared/LoadComponent';
import { RedirectAs404 } from './shared/RouteErrors';
import Message from './shared/Message';

function App() {
  const [sliderCollapsed, setSliderCollapsed] = useState<boolean>(window.innerWidth < 767 ? true : false);
  const [currentMenu, setCurrentMenu] = useState<string[]>([]);
  const currentMenuRef = useRef<string[]>();
  currentMenuRef.current = currentMenu;
  const [currentSubMenu, setCurrentSubMenu] = useState<string[]>([]);
  const authContext = useContext(AuthenticationContext);
  const location = useLocation();
  const history = useHistory();
  const [menuItems, setMenuItems] = useState<ItemType[]>([]);
  const [headerMenuItems, setHeaderMenuItems] = useState<ItemType[]>([]);
  const [showUserInfo, setShowUserInfo] = useState<boolean>(false);
  const [permissionValues, setPermissionValues] = useState<string[]>([]);
  const [showUnauthorized, setShowUnauthorized] = useState<boolean>(true);
  const baseSiteClass = 'site-layout';
  const [siteClass, setSiteClass] = useState<string>(baseSiteClass);
  const [searchingWiki, setSearchingWiki] = useState(false);
  const [currentRouteWikiId, setCurrentRouteWikiId] = useState<string>();
  const { fetch: originalFetch } = window;

  useEffect(() => {
    window.fetch = async (...args) => {
      //overwite browser built-in function window.fetch to check if token is expired whenever a http request is made, then continue as normal.
      Routes.redirectIfTokenExpired(authContext.userInfo);

      const [resource, config] = args;
      const response = await originalFetch(resource, config);
      return response;
    };

    if (Routes.inIframe() === false && authContext.userInfo?.permissions?.eastonscheduling_only !== true) {
      initMenu();
      initHeaderMenu();
    }

    const handleResize = () => {
      if (window.innerWidth < 767) {
        setSliderCollapsed(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const createMenuItemFromRoute = (route: PageInfo) => {
    return (
      route.MenuLabel &&
      (route.MenuDebugOnly !== true || $isRunningLocally) &&
      authContext.canUserAccessFunction(route.PermissionFunction)
    );
  };

  const initMenu = () => {
    const newMenuItems: ItemType[] = [];
    const routesList = Object.values(Routes.RoutesList);
    const addedKeys: string[] = [];
    routesList.forEach((route) => {
      const menuItem = getMenuItem(route, routesList, addedKeys);
      if (menuItem) {
        newMenuItems.push(menuItem);
      }
    });
    setMenuItems(newMenuItems);
  };

  const getMenuItem = (route: PageInfo, routesList: PageInfo[], addedKeys: string[]): ItemType | undefined => {
    if (!addedKeys.some((k) => k === route.MenuKey) && createMenuItemFromRoute(route)) {
      if (route.ParentMenu === undefined || routesList.length === 0) {
        //recursion not needed or reached bottom layer of recursion
        addedKeys.push(route.MenuKey);
        if (route.MenuKey == Routes.wiki_clicksearch_key) {
          return {
            key: route.MenuKey,
            label: <span onClick={onWikiSearchClick}>{route.MenuLabel}</span>,
            icon: route.MenuIcon,
          };
        } else {
          return {
            key: route.MenuKey,
            label: (
              <Link to={route.Path} target="_self" style={{ textDecoration: 'none' }}>
                {route.MenuLabel}
              </Link>
            ),
            icon: route.MenuIcon,
          };
        }
      } else {
        const allRoutesInParent = routesList.filter(
          (r) => r.ParentMenu && r.ParentMenu.Key == route.ParentMenu!.Key && createMenuItemFromRoute(r)
        );
        const distinctGroups: (string | undefined)[] = [];
        allRoutesInParent.map((r) => {
          if (distinctGroups.indexOf(r.MenuGroup?.Label) === -1) {
            distinctGroups.push(r.MenuGroup?.Label);
          }
        });

        let menuItemChildren: ItemType[] = [];
        distinctGroups.forEach((g) => {
          const allRoutesInParentAndGroup = allRoutesInParent.filter((r) => r.MenuGroup?.Label === g);
          const groupItems: ItemType[] = [];
          allRoutesInParentAndGroup.forEach((gr) => {
            const groupItemMenuItem = getMenuItem(gr, [], addedKeys); //only 1 layer of recursion currently needed, since the deepest our menu structure goes is parent -> group -> item
            if (groupItemMenuItem) {
              groupItems.push(groupItemMenuItem);
            }
          });

          if (g) {
            menuItemChildren.push({
              label: g,
              type: 'group',
              children: groupItems,
            });
          } else {
            menuItemChildren = menuItemChildren.concat(groupItems);
          }
        });

        return {
          key: route.ParentMenu.Key,
          onTitleClick,
          label: route.ParentMenu.Label,
          icon: route.ParentMenu.Icon,
          children: menuItemChildren,
        };
      }
    }
  };

  const onWikiSearchClick = () => {
    setSiteClass(baseSiteClass + ' searching-wiki');
    setSearchingWiki(true);
  };

  const initHeaderMenu = () => {
    const headerMenu: ItemType[] = [];
    headerMenu.push({
      key: 'Alerts',
      label: (
        <Popover
          title={<small>You have {authContext.alertInfo?.totalAlerts || 0} alerts</small>}
          content={
            <Link to={Routes.RoutesList.SYSTEMALERTS.Path} target="_self" style={{ textDecoration: 'none' }}>
              <span>
                <ExclamationCircleOutlined style={{ color: '#EED202' }} />{' '}
                {(authContext.alertInfo?.newAlerts || 0) + ' '}
                new alerts
              </span>
            </Link>
          }
        >
          <Badge
            size="small"
            count={authContext.alertInfo?.newAlerts}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <BellFilled style={{ fontSize: '20px', color: '#fff', marginTop: '15%', marginLeft: '0px' }}></BellFilled>
          </Badge>
        </Popover>
      ),
    });
    if (authContext.userInfo !== undefined && authContext.userInfo.avatarImageURL != null) {
      headerMenu.push({
        onClick: () => {
          const p: string[] = [];
          if (authContext.userInfo?.permissions != null) {
            Object.entries(authContext.userInfo?.permissions).map(([key, value]) => {
              if (value) {
                p.push(key);
              }
            });
          }

          setPermissionValues(p.sort());
          setShowUserInfo(true);
        },
        key: 'User',
        label: (
          <div className="site-layout-header-user">
            <img src={authContext.userInfo.avatarImageURL} className="avatar"></img>
            {authContext.userInfo.displayName}
          </div>
        ),
      });
    }
    if ($showPreviewLink === true && authContext.userInfo !== undefined && authContext.userInfo.token !== null) {
      //when react is in production (not on the preview site), have a link to the preview site which will have the old angular code.
      headerMenu.push({
        key: 'Angular',
        label: (
          <BookFilled
            style={{ fontSize: '20px', color: '#fff', marginLeft: '0px' }}
            onClick={() => (window.location.href = $previewUrl + '?token=' + authContext.userInfo!.token)}
            title={'Go To Old Website'}
          ></BookFilled>
        ),
      });
    }
    setHeaderMenuItems(headerMenu);
  };

  useEffect(() => {
    //Determine which Route is currently used based on url. This
    //calculation is needed to handle url changes triggered outside of this file.
    const curRoute = Routes.getCurrentRouteFromLocation(location.pathname);
    setCurrentRoute(curRoute);

    //display warning if web.config contains any production connection values while
    //running locally to help prevent accidently modifying production data.
    let configWarning = '';
    if ($showDbWarning) configWarning += 'Using production db connection. ';
    if ($showGpWarning) configWarning += 'Using production GP connection. ';
    if ($showWowWarning) configWarning += 'Using production WOW connection. ';
    if ($showAuthNetWarning) configWarning += 'Using production Authorize.net connection. ';
    if ($showAwsWarning) configWarning += 'Using production AWS connection. ';
    if (configWarning !== '') Message.info(configWarning);
  }, [useLocation()]);

  const setCurrentRoute = (pageInfo: PageInfo) => {
    // Select menu items based on route
    setCurrentMenu([pageInfo.ParentMenu ? pageInfo.ParentMenu.Key : pageInfo.MenuKey]);
    setCurrentSubMenu([pageInfo.MenuKey]);
    setShowUnauthorized(!authContext.canUserAccessFunction(pageInfo.PermissionFunction));
    setCurrentRouteWikiId(pageInfo.WikiId);
  };

  function onTitleClick(title: any) {
    if (!!currentMenuRef.current && currentMenuRef.current.length > 0 && currentMenuRef.current[0] === title.key) {
      setCurrentMenu([]);
    } else {
      setCurrentMenu([title.key]);
    }
  }

  const toggleSlider = () => {
    setSliderCollapsed(!sliderCollapsed);
  };

  function ToggleIcon() {
    if (sliderCollapsed) {
      return <MenuUnfoldOutlined onClick={toggleSlider} />;
    } else {
      return <MenuFoldOutlined onClick={toggleSlider} />;
    }
  }

  function getContentClassName(): string {
    const className = 'site-layout-content';
    if (sliderCollapsed) {
      return className + '-collapsed';
    }
    return className;
  }
  function getLogoClassName(): string {
    const className = 'site-layout-header-logo';
    if (sliderCollapsed) {
      return className + '-collapsed';
    }
    return className;
  }

  function getSidebarClassName(): string {
    const className = 'site-layout-sidebar';
    if (sliderCollapsed) {
      return className + '-collapsed';
    }
    return className;
  }

  const getMenuClassName = () => {
    const className = 'side-menu';
    if (sliderCollapsed) {
      return className + '-collapsed';
    }
    return className;
  };

  const getMenuRootClassName = () => {
    const className = '';
    if (sliderCollapsed) {
      return className + 'side-menu-collapsed';
    }
    return className;
  };

  const getUserStartPage = () => {
    const sortedRoutes = Object.values(Routes.RoutesList).sort((a, b) =>
      (!a.StartPagePriority && !b.StartPagePriority) ||
      (a.StartPagePriority && b.StartPagePriority && a.StartPagePriority == b.StartPagePriority)
        ? 0
        : (a.StartPagePriority && !b.StartPagePriority) ||
          (a.StartPagePriority && b.StartPagePriority && a.StartPagePriority < b.StartPagePriority)
        ? -1
        : 1
    );
    const pageInfo = sortedRoutes.find(
      (a) => !a.Path.includes(':') && a.StartPage === true && authContext.canUserAccessFunction(a.PermissionFunction)
    );
    if (pageInfo) {
      return <Redirect to={pageInfo.Path} />;
    } else {
      return <Unauthorized />;
    }
  };

  const onMenuItemClick = () => {
    if (window.innerWidth < 767) setSliderCollapsed(true);
  };

  const productionRoutes = [
    { value: Routes.RoutesList.PRODUCTION_BAGGING.Path, key: 1, teamView: '', areaId: 1, exact: true },
    {
      value: Routes.RoutesList.TRACKING_VERIFICATION_STOCKYARDS.Path,
      key: '1_VERIFICATION',
      teamView: 'VERIFICATION',
      areaId: 1,
      exact: true,
    },
    { value: Routes.RoutesList.PRODUCTION_MIXING.Path, key: 2, teamView: '', areaId: 2 },
    { value: Routes.RoutesList.PRODUCTION_AMAFERM_DRYING.Path, key: 3, teamView: '', areaId: 3 },
    { value: Routes.RoutesList.SANITATION_STOCKYARDS.Path, key: 4, teamView: '', areaId: 4 },
    { value: Routes.RoutesList.INVENTORY_CHECKLISTS.Path, key: 5, teamView: '', areaId: 5 },
    { value: Routes.RoutesList.FACILITIES_STOCKYARDS.Path, key: 6, teamView: '', areaId: 6 },
    { value: Routes.RoutesList.FACILITIES_EASTON.Path, key: 9, teamView: '', areaId: 9 },
    { value: Routes.RoutesList.MAINTENANCE_CHECKLISTS_STOCKYARDS.Path, key: 7, teamView: '', areaId: 7 },
    {
      value: Routes.RoutesList.PRODUCTION_FERMENTATION_EASTON.Path,
      key: '8_fermentation',
      teamView: '',
      areaId: 8,
      exact: true,
      tankType: 'FE',
    },
    {
      value: Routes.RoutesList.PRODUCTION_STORAGE_EASTON.Path,
      key: '8_storage',
      teamView: '',
      areaId: 8,
      exact: true,
      tankType: 'BB',
    },
    {
      value: Routes.RoutesList.TRACKING_FERMENTATION_EASTON.Path,
      key: '8_Fermentation_VERIFICATION',
      teamView: 'FERMENTATION_VERIFICATION',
      areaId: 8,
      exact: true,
      tankType: 'FE',
    },
    {
      value: Routes.RoutesList.TRACKING_STORAGE_EASTON.Path,
      key: '8_Storage_VERIFICATION',
      teamView: 'FERMENTATION_VERIFICATION',
      areaId: 8,
      exact: true,
      tankType: 'BB',
    },
    { value: Routes.RoutesList.TRACKING_FERMENTATION_EASTON.Path, key: 9, teamView: '', areaId: 9 },
    { value: Routes.RoutesList.SANITATION_EASTON.Path, key: 10, teamView: '', areaId: 10 },
    { value: Routes.RoutesList.MAINTENANCE_CHECKLISTS_EASTON.Path, key: 11, teamView: '', areaId: 11 },
    { value: Routes.RoutesList.PRODUCTION_CHECKLISTS_EASTON.Path, key: 12, teamView: '', areaId: 12 },
    { value: Routes.RoutesList.PRODUCTION_SEEDLAB_EASTON.Path, key: 13, teamView: '', areaId: 13 },
    { value: Routes.RoutesList.PRODUCTION_TestingLab.Path, key: 14, teamView: '', areaId: 14 },
    { value: Routes.RoutesList.PRODUCTION_MEDIAMIXING_EASTON.Path, key: 15, teamView: '', areaId: 15, exact: true },
    {
      value: Routes.RoutesList.TRACKING_MEDIAMIXING_EASTON.Path,
      key: '15_VERIFICATION',
      teamView: 'MEDIA_MIXING_VERIFICATION',
      areaId: 15,
      exact: true,
    },
    { value: Routes.RoutesList.PRODUCTION_EkonekDryer_EASTON.Path, key: 16, teamView: '', areaId: 16 },
    { value: Routes.RoutesList.PRODUCTION_PtBagger_EASTON.Path, key: 17, teamView: '', areaId: 17 },
    { value: Routes.RoutesList.PRODUCTION_LAB_EASTON.Path, key: 18, teamView: 'EASTON_LAB', areaId: 18 },
    {
      value: Routes.RoutesList.PRODUCTION_ANALYTICAL_LAB_EASTON.Path,
      key: 24,
      teamView: '',
      areaId: 24,
    },
    {
      value: Routes.RoutesList.PRODUCTION_LAB_EASTON_VERIFICATION.Path,
      key: '18v',
      teamView: 'FERMENTATION_VERIFICATION',
      areaId: 18,
    },
    { value: Routes.RoutesList.QUALITY_STOCKYARDS.Path, key: 21, teamView: '', areaId: 21 },
    { value: Routes.RoutesList.QUALITY_EASTON.Path, key: 22, teamView: '', areaId: 22 },
    { value: Routes.RoutesList.DOWNSTREAM.Path, key: 23, teamView: '', areaId: 23 },
  ];

  if (Routes.inIframe() === true) {
    return (
      <Layout className={siteClass} id="qci-app-layout" hasSider={false}>
        <Content>
          {showUnauthorized ? (
            <Switch>
              <Route
                render={() => (
                  <React.Suspense fallback={<LoadComponent />}>
                    <Unauthorized />
                  </React.Suspense>
                )}
              ></Route>
            </Switch>
          ) : (
            <Switch>
              <Route
                path={Routes.RoutesList.SALESORDERS_CREATE_STANDALONE.Path}
                render={() => (
                  <React.Suspense fallback={<LoadComponent />}>
                    <Create_Standalone />
                  </React.Suspense>
                )}
              ></Route>
              <Route
                path={Routes.RoutesList.SALESORDERS_EDIT_STANDALONE.Path}
                render={() => (
                  <React.Suspense fallback={<LoadComponent />}>
                    <SalesOrderEdit_Standalone />
                  </React.Suspense>
                )}
              ></Route>
              <Route component={RedirectAs404}>
                {/* none of the previous routes match the current route. display page not found error. */}
              </Route>
            </Switch>
          )}
        </Content>
      </Layout>
    );
  } else if (authContext.userInfo?.permissions?.eastonscheduling_only === true) {
    return (
      <Layout className={siteClass} id="qci-app-layout" hasSider={false}>
        <Content>
          {showUnauthorized ? (
            <Switch>
              <Route
                render={() => (
                  <React.Suspense fallback={<LoadComponent />}>
                    <Unauthorized />
                  </React.Suspense>
                )}
              ></Route>
            </Switch>
          ) : (
            <Switch>
              <Route
                path={Routes.RoutesList.PRODUCTION_Scheduling_EASTON.Path}
                render={() => (
                  <React.Suspense fallback={<LoadComponent />}>
                    <PlanningPage />
                  </React.Suspense>
                )}
              ></Route>
              <Route component={RedirectAs404}>
                {/* none of the previous routes match the current route. display page not found error. */}
              </Route>
            </Switch>
          )}
        </Content>
      </Layout>
    );
  } else {
    return (
      <Layout className={siteClass} id="qci-app-layout" hasSider={true}>
        <Sider
          style={{ overflow: 'hidden auto', zIndex: 1, height: '100%' }}
          width="220"
          breakpoint="lg"
          collapsedWidth="0"
          className={getSidebarClassName()}
          collapsed={sliderCollapsed}
        >
          <Menu
            onClick={onMenuItemClick}
            mode="inline"
            theme="light"
            className={getMenuClassName()}
            rootClassName={getMenuRootClassName()}
            openKeys={currentMenu}
            selectedKeys={currentSubMenu}
            items={menuItems}
            hidden={sliderCollapsed}
          />
        </Sider>
        <Header style={{ zIndex: 2 }} className="site-layout-header">
          <div className={getLogoClassName()} onClick={() => history.push(Routes.RoutesList.HOME.Path!)}>
            <img src={Logo} alt="Logo" className="site-layout-header-logo-img" />
          </div>
          <ToggleIcon />
          <Menu className="site-layout-header-menu" mode="horizontal" items={headerMenuItems} />
        </Header>
        <Modal
          open={showUserInfo}
          title={authContext.userInfo?.displayName}
          key={authContext.userInfo?.id}
          onOk={() => setShowUserInfo(false)}
          onCancel={() => setShowUserInfo(false)}
        >
          <List
            header="Permissions"
            size="small"
            dataSource={permissionValues}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          ></List>
        </Modal>
        <Content className={getContentClassName()}>
          {showUnauthorized ? (
            <Switch>
              <Route
                render={() => (
                  <React.Suspense fallback={<LoadComponent />}>
                    <Unauthorized />
                  </React.Suspense>
                )}
              ></Route>
            </Switch>
          ) : (
            <>
              {searchingWiki && (
                <Space
                  style={{
                    backgroundColor: '#d9edf7',
                    border: '1px solid #91caff',
                    padding: '20px',
                    borderRadius: '20px',
                  }}
                >
                  Click a highlighted element to display its documentation. Elements that are not highlighted do not
                  have documentation yet.
                  <Button
                    onClick={() => {
                      setSiteClass(baseSiteClass);
                      setSearchingWiki(false);
                    }}
                  >
                    Cancel
                  </Button>
                  {currentRouteWikiId && (
                    <Button
                      onClick={() => {
                        window.open(
                          Routes.generate(Routes.RoutesList.WIKI_DETAIL.Path, { id: currentRouteWikiId }),
                          '_blank',
                          'noopener,noreferrer'
                        );
                      }}
                    >
                      View Current Page&apos;s Documentation
                    </Button>
                  )}
                </Space>
              )}
              <Switch>
                <Route path={Routes.RoutesList.HOME.Path} exact render={() => getUserStartPage()}>
                  {/* backoffice initially sends no path, only the token url parameter. calculate the initial page to display based on user permissions. */}
                </Route>

                <Route
                  path={Routes.RoutesList.WIKI_LIST.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <WikiList />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.WIKI_DETAIL.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <WikiDetail />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.CCARD_RETRY.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <CreditCardRetry />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.CUSTOMER_DESYNC.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <CustomerDesync />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.FREIGHT.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <FreightPage searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.TIMES.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <TimesPage searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.BRANDS.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <BrandsList />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.BRANDS_DETAIL.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <BrandsDetail />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.CUSTOMPRICING.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <CustomPricing />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.DISTRIBUTIONS.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <DistributionsList searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.PROMOTIONS.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <PromotionsList />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.BIOZYMEBUCKS.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <DealerRetreatDiscountsList />
                    </React.Suspense>
                  )}
                ></Route>
                <Route
                  path={Routes.RoutesList.PRODUCT_TYPES.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <ProductType searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.REPORTS_REPORT.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <ReportsList searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.REPORTS_LIST.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <ReportsList searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SYSTEMALERTS.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <SystemAlerts />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.FORECASTING.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <ForecastingList searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SUPPLYCHAIN_FromSystemAlert.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <SupplyChain searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SUPPLYCHAIN.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <SupplyChain searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.REWORKS.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <Reworks />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.MIXINGSCHEDULER.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <MixingScheduler />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.CHECKPRICING.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <CheckPricing searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.PURCHASING_PONUM.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <Purchasing searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.PURCHASING.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <Purchasing searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SALESORDERS_CREATE.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <Create />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.VENDORS.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <Vendors />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.VENDORS_Detail.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <VendorDetails />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.VENDORS_Request.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <RequestedTable />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SUPPLYCHAIN.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <SupplyChain searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SUPPLYCHAIN_Detail_File.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <SupplyChainDetail searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SUPPLYCHAIN_Detail_Formula.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <SupplyChainDetail searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SUPPLYCHAIN_Detail_HazardAnalysis.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <SupplyChainDetail searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SUPPLYCHAIN_Detail.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <SupplyChainDetail searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SUPPLYCHAIN_RequestDetail.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <SupplyChainReqDetail searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SALESORDERS_LIST.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <SalesOrder />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.SALESORDERS_EDIT.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <SalesOrderEdit />
                    </React.Suspense>
                  )}
                ></Route>

                {productionRoutes.map((r) => {
                  return (
                    <Route
                      key={r.key}
                      path={r.value}
                      exact={r.exact}
                      render={() => (
                        <React.Suspense fallback={<LoadComponent />}>
                          <TrackingPage
                            uid={r.key}
                            key={r.key}
                            areaId={r.areaId}
                            teamView={r.teamView}
                            tankType={r.tankType}
                          />
                        </React.Suspense>
                      )}
                    ></Route>
                  );
                })}

                <Route
                  path={Routes.RoutesList.PRODUCTION_AO_REQUEST_DETAIL.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <AoRequestDetail />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.PRODUCTION_AO_REQUEST.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <AoRequest />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.PRODUCTION_Scheduling_EASTON.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <PlanningPage />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.PRODUCTION_SetupFromPlanning_CORPORATE.Path}
                  exact
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <ProductionSetupPage />
                    </React.Suspense>
                  )}
                ></Route>
                <Route
                  path={Routes.RoutesList.PRODUCTION_Setup_CORPORATE.Path}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <ProductionSetupPage />
                    </React.Suspense>
                  )}
                ></Route>

                <Route
                  path={Routes.RoutesList.TRACKING_TRACKINGSETUP_CORPORATE.Path}
                  exact={true}
                  render={() => (
                    <React.Suspense fallback={<LoadComponent />}>
                      <Tracking searchingWiki={searchingWiki} />
                    </React.Suspense>
                  )}
                ></Route>
                <Route component={RedirectAs404}>
                  {/* none of the previous routes match the current route. display page not found error. */}
                </Route>
              </Switch>
            </>
          )}
        </Content>
      </Layout>
    );
  }
}

export default App;
