/* eslint-disable no-duplicate-case */
import Spin from 'antd/lib/spin';
import React, { createContext, useEffect, useRef, useState } from 'react';
import ConfigApiService from '../api/ConfigApiService';
import SystemAlertsApiService from '../api/SystemAlertsApiService';
import AlertCountVM from '../models/AlertCountVM';
import UserInfo from '../models/UserInfo';
import { SpinCentered } from '../shared/SpinCentered';
import Routes from '../consts/Routes';

interface AuthenticationContextType {
  userInfo?: UserInfo;
  alertInfo?: { totalAlerts: number; newAlerts: number };
  canUserAccessFunction: (func: string) => boolean;
  canUserAccessAnyFunctions: (funcs: string[]) => boolean;
  updateAlertCount: () => void;
}

// When updating the existing PermissionFunctions string values, please update the string values in tracking.Functions if any
export enum PermissionFunctions {
  AOREQUEST = 'AoRequest',
  BIOZYMEBUCKS = 'BioZymeBucks',
  BRANDS = 'Brands',
  FORECASTING = 'Forecasting',
  FORECASTING_WRITE = 'Forecasting_Write',
  PRODUCTTYPESWrite = 'ProductTypes_Write',
  PRICING = 'Pricing',
  REPORTS = 'Reports',
  MIXINGSCHEDULING = 'MixingScheduler',
  SCHEDULINGMANCHANGE = 'SchedulingManChange',
  REWORKS = 'Reworks',
  PURCHASING = 'Purchasing',
  PURCHASINGWRITE = 'PurchasingWrite',
  TRACKINGSETUP = 'TrackingSetup',
  TRACKINGTEAMNOTIFICATIONS = 'TrackingTeamNotifications',
  TRACKINGQUICKCOMPLETE = 'TrackingQuickComplete',
  VENDORS = 'Vendors',
  VENDORS_SPENDING = 'VendorsHideSpending',
  PURCHASING_ITEMCOST = 'PurchasingItemCost',
  SUPPLYCHAIN = 'SupplyChain',
  SUPPLYCHAIN_Write = 'SupplyChainWrite',
  SUPPLYCHAIN_Request = 'SupplyChainRequest',
  SUPPLYCHAIN_Description = 'SupplyChainDescription',
  SUPPLYCHAIN_Files = 'SupplyChainFiles',
  SUPPLYCHAIN_PricingDetails = 'SupplyChainPricingDetails',
  SALESORDERManager = 'SalesOrdersManager',
  SALESORDEROverrideMin = 'SalesOrdersOverrideMin',
  SALESORDEREDIT_Delete = 'SalesOrderEditDelete',
  SEEDLAB = 'SeedLab',
  VERIFICATION_FINAL_PRODUCT = 'VerificationFinalProduct',
  MEDIAMIXING = 'MediaMixing',
  FERMENTATION = 'Fermentation',
  STORAGE = 'Storage',
  MEDIAMIXING_VERIFICATION = 'MediaMixingVerification',
  FERMENTATION_VERIFICATION = 'FermentationVerification',
  FREIGHT = 'Freight',
  FREIGHT_WRITE = 'FreightWrite',
  SALES_ORDER_LIST = 'SalesOrderList',
  VENDORS_REQUEST = 'VendorsRequest',
  EKONEK_DRYER = 'EkonekDryer',
  PT_BAGGING = 'PtBagging',
  TestingLab = 'TestingLab',
  SHIPPINGTIME = 'ShippingTime',
  VERIFICATION = 'Verification',
  INVENTORY_CHECKLIST = 'Inventory',
  AMAFERM_DRYING = 'Amaferm Drying',
  MIXING = 'Mixing',
  BAGGING = 'Bagging',
  EASTON_SCHEDULING = 'EastonScheduling',
  SANITATION = 'Sanitation',
  SANITATION_EASTON = 'SanitationEaston',
  FACILITIES = 'Facilities',
  FACILITIES_EASTON = 'FacilitiesEaston',
  MAINTENANCE_CHECKLIST = 'MaintenanceChecklist',
  MAINTENANCE_CHECKLIST_EASTON = 'MaintenanceChecklistEaston',
  LAB_EASTON_CHECKLISTS = 'LabEastonChecklists',
  QUALITY_STOCKYARDS_CHECKLISTS = 'QualityStockyardsChecklists',
  QUALITY_EASTON_CHECKLISTS = 'QualityEastonChecklists',
  MIXING_SUPERVISOR = 'MixingSupervisor',
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthenticationContext = createContext<AuthenticationContextType>(undefined!);

export const AuthenticationProvider = (props: React.PropsWithChildren<any>) => {
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [alertCount, setAlertCount] = useState<AlertCountVM>();
  const QCI_UserIDs = [289, 546];
  const checkAuthFrequencyMs = 10000;
  const checkAuthTries = 2;

  useEffect(() => {
    GetUserInfo(0);
  }, []);

  function GetUserInfo(tries: number) {
    ConfigApiService.getUserInfo()
      .then((res) => {
        if (res.isAuthenticated) {
          //successfully authenticated. set user fields.
          setUserInfo(res);
          const defaultSiteClass = 'back-office';
          const siteClass =
            $isRunningLocally || !res.tokenOrigin
              ? defaultSiteClass
              : res.tokenOrigin === $originBackoffice
              ? 'back-office'
              : res.tokenOrigin === $originPartnerCenter
              ? 'partner-center'
              : res.tokenOrigin === $originDealerCenter
              ? 'dealer-center'
              : defaultSiteClass;
          document.body.classList.add(siteClass); //use to apply styling based on parent site for pages opened through an iframe

          setLoading(false);
          getAlertCount();
        } else if (tries >= checkAuthTries) {
          //max number of attempts to get user info reached, stop trying.
          setLoading(false);
          setAlertCount({ totalAlerts: 0, newAlerts: 0 });
        } else {
          //not successfully authenticated, and still under max number of attempts. retry, but wait a few seconds in case the database wan't responding. sometimes it takes
          //a bit to start responding.
          tries++;
          setTimeout(() => {
            GetUserInfo(tries);
          }, checkAuthFrequencyMs);
        }
      })
      .catch(() => {
        //an exeption occured while authenticating. treat as if res.isAuthenticated = false
        if (tries >= checkAuthTries) {
          setLoading(false);
          setAlertCount({ totalAlerts: 0, newAlerts: 0 });
        } else {
          tries++;
          setTimeout(() => {
            GetUserInfo(tries);
          }, checkAuthFrequencyMs);
        }
      });
  }

  const getAlertCount = () => {
    SystemAlertsApiService.getAlertCount()
      .then((a) => {
        setAlertCount({ totalAlerts: a.totalAlerts, newAlerts: a.newAlerts });
      })
      .catch(() => {
        setAlertCount({ totalAlerts: 0, newAlerts: 0 });
      });
  };

  const updateAlertCount = async () => {
    setAlertCount(await SystemAlertsApiService.getAlertCount());
  };

  const canUserAccessFunction = (func: string) => {
    if (func == '') return !!userInfo && userInfo.isAuthenticated;

    if (userInfo && userInfo.isAuthenticated && userInfo.permissions) {
      const permissions = Routes.getPermissionsThatAllowFunction(func);
      const hasPermissions =
        permissions.length > 0 ? permissions.some((permission) => userInfo.permissions![permission] === true) : true;

      switch (func) {
        case PermissionFunctions.SALES_ORDER_LIST:
          return QCI_UserIDs.includes(userInfo.id);
        default:
          return hasPermissions;
      }
    }
    return false;
  };

  const canUserAccessAnyFunctions = (funcs: string[]) => {
    return funcs.some((f) => canUserAccessFunction(f));
  };

  return (
    <AuthenticationContext.Provider
      value={{
        userInfo: userInfo,
        canUserAccessFunction,
        canUserAccessAnyFunctions,
        alertInfo: alertCount,
        updateAlertCount,
      }}
    >
      {loading || !alertCount ? <SpinCentered text={'loading...'}></SpinCentered> : <>{props.children}</>}
    </AuthenticationContext.Provider>
  );
};
