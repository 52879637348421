import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthenticationProvider } from './auth/AuthenticationContext';
import PublicLayout from './layout/PublicLayout';
import NotFound from './shared/NotFound';
import { Route, Router } from 'react-router';
import History from './utils/HistoryUtil';

ReactDOM.render(
  <React.StrictMode>
    <Router history={History}>
      <Route
        render={(props) =>
          // @ts-ignore
          props.location.state && props.location.state.is404 ? (
            <PublicLayout>
              <NotFound />
            </PublicLayout>
          ) : (
            <AuthenticationProvider>
              <App />
            </AuthenticationProvider>
          )
        }
      />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
