import { Layout, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import UnexpectedError from '../shared/UnexpectedError';

const { Header } = Layout;
interface PublicLayout2Props {}

const PublicLayout = (props: React.PropsWithChildren<PublicLayout2Props>) => {
  return (
    <Layout style={{ height: '100%' }}>
      <Header className="public">
        <Menu mode="horizontal">
          {/* <Menu.Item key="assistance">
            <Popover
              placement="bottomRight"
              content={HeaderContactContent}
              trigger="click"
              overlayClassName="header-contact-content"
            >
              <Button type="link">
                <PhoneOutlined /> Assistance <DownOutlined />
              </Button>
            </Popover>
          </Menu.Item> */}
        </Menu>
      </Header>
      <Layout.Content>
        <Layout>
          <Layout.Content id="app-main" className="page-content">
            <UnexpectedError>{props.children}</UnexpectedError>
          </Layout.Content>
        </Layout>
      </Layout.Content>
    </Layout>
  );
};

export default PublicLayout;
