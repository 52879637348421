import { Layout } from 'antd';
import React from 'react';
import { SpinCentered } from './SpinCentered';

export const LoadComponent = () => {
  return (
    <Layout>
      <Layout.Content>
        <SpinCentered text={'loading...'}></SpinCentered>
      </Layout.Content>
    </Layout>
  );
};
