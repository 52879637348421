
// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import UserInfo from '../models/UserInfo';

export class ConfigApiService extends BaseApi {

    // get: ConfigApiService.GetConfig
    public getConfig_Url = (): string => {
        let url = `api/config/getconfig`;

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public getConfig = (): Promise<void> => {
        return this.SendRequest<any, void>(null, 'get', this.getConfig_Url(), true, false);
    }

    // get: ConfigApiService.GetUserInfo
    public getUserInfo_Url = (): string => {
        let url = `api/config/userinfo`;

        url = url.replace(/null|undefined/gi, '');
        return url;
    }

    public getUserInfo = (): Promise<UserInfo> => {
        return this.SendRequest<any, UserInfo>(null, 'get', this.getUserInfo_Url(), true, false);
    }
}
var service = new ConfigApiService();
export default service;
